import React from "react";
import { ReactSVG } from "react-svg";
import classNames from "classnames";
import * as styles from "./linkWithSvg.module.scss";

const LinkWithSVG = ({ fileUrl, svgSrc, label, className, secondary, iconRight, ...props }) => {
  const classnames = classNames(`${styles.link} ${className}`, {
    [styles.secondary]: secondary,
    [styles.iconRight]: iconRight,
  });

  return (
    <div className={classnames}>
      <a
        href={fileUrl}
        target="_blank"
        rel="noreferrer"
        {...props}>
        {svgSrc && (
          <ReactSVG
            data-testid="svg-icon"
            src={svgSrc}
            className={styles.svg}
          />
        )}
        {label}
      </a>
    </div>
  );
};

export default LinkWithSVG;
