import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Button from "../../atoms/Button";
import Popup from "../Popup";
import Loading from "../../atoms/Loading";
import * as styles from "./previewDocument.module.scss";

const PreviewDocument = ({ fileUrl, Component }) => {
  const { t } = useTranslation();
  const [isopen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);

  return typeof Component === "function" && typeof fileUrl === "string" ? (
    <>
      <Component
        data-testid="preview-documents-trigger"
        onClick={handleOpenModal}
      />
      {isopen && (
        <Popup
          className={styles.popup}
          onClickOutside={handleCloseModal}
          onKeypressEsc={handleCloseModal}>
          <Iframe
            fileUrl={fileUrl}
            className={styles.popup_iframe}
          />
          <section className={styles.popup_action}>
            <Button
              onClick={handleCloseModal}
              label={t("Close")}
              primary></Button>
          </section>
        </Popup>
      )}
    </>
  ) : null;
};

const Iframe = ({ fileUrl, className }) => {
  const [iframeLoading, setIframeLoadin] = useState(true);
  const handleOnloadIframe = () => {
    setIframeLoadin(false);
  };

  return (
    <>
      {iframeLoading && (
        <div className={styles.popup_loaderWrapper}>
          <Loading />
        </div>
      )}
      <iframe
        data-testid="preview-documents-iframe"
        onLoad={handleOnloadIframe}
        src={fileUrl}
        frameBorder="0"
        className={className}
        title="Modal preview"></iframe>
    </>
  );
};

export default PreviewDocument;
